@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();
$primary: mat.define-palette(mat.$light-blue-palette, 800);
//$primary-dark: mat-palette($mat-light-blue, A700);

$success: mat.define-palette(mat.$green-palette, 600);
$danger: mat.define-palette(mat.$red-palette, 700);
$urgent: mat.define-palette(mat.$yellow-palette, 700);
$accent: mat.define-palette(mat.$light-blue-palette, 900);
$warn: mat.define-palette(mat.$orange-palette, 600);

// TEMA
$theme: mat.define-light-theme($primary, $accent, $warn);
//$theme: mat-dark-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);


$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

.success-color {
  color: mat.get-color-from-palette($success)
}

.primary-color {
  color: mat.get-color-from-palette(map-get($theme, primary));
}

.accent-color {
  color: mat.get-color-from-palette(map-get($theme, accent));
}

.danger-color {
  color: mat.get-color-from-palette($danger);
}

.warning-color {
  color: mat.get-color-from-palette(map-get($theme, warn));
}

.urgent-color {
  color: mat.get-color-from-palette($urgent);

}

.bkg-success-color {
  background-color: mat.get-color-from-palette($success);
}

.bkg-primary-color { //nao urgente
  background-color: mat.get-color-from-palette(map-get($theme, primary));
}

.bkg-accent-color { //pouco urgente
  background-color: mat.get-color-from-palette(map-get($theme, accent));
}

.bkg-danger-color { //extr. urgente
  background: mat.get-color-from-palette($danger);
}

.bkg-warning-color { //muito urgente
  background-color: mat.get-color-from-palette(map-get($theme, warn));
}

.bkg-urgent-color { //urgente
  background-color: mat.get-color-from-palette($urgent);
}


// imagens em gadio group desativados
.mat-radio-button.mat-radio-disabled .mat-radio-label-content img {
  opacity: 0.5;
}

mat-icon img {
  display: flex;
  margin: auto;
}

mat-toolbar {
  height: 48px !important;
}

//// Amplify
//
//.amplify-form-button {
//  background-color: mat-color(map-get($theme, accent)) !important;
//  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
//  box-sizing: border-box;
//  position: relative;
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//  cursor: pointer;
//  outline: 0;
//  border: none;
//  -webkit-tap-highlight-color: transparent;
//  display: inline-block;
//  white-space: nowrap;
//  text-decoration: none;
//  vertical-align: baseline;
//  text-align: center;
//  margin: 0;
//  min-width: 64px;
//  line-height: 36px;
//  padding: 0 16px;
//  border-radius: 4px;
//  overflow: visible;
//  transform: translate3d(0, 0, 0);
//  transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
//}

// sombra preta da tela quando menu lateral abre
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #fafafa00;
}

mat-tab-group {
  contain: content;
}

//

//.info-color {
//  color: mat-color(mat-palette($mat-light-blue, 800));
//}
//
//.card-color {
//  background-color: mat-color($background, card);
//}
//
//.card-of-modal-color {
//  background-color: if($is-dark-theme, #4d4d4d, #fafafa);
//}
//
//.text-default-color {
//  color: mat-color($foreground, text);
//}
//
.text-subtitle-color {
  color: mat.get-color-from-palette($foreground, secondary-text);
}
